<template>
  <div>
    <div class="rcs-card">
      <div class="d-flex flex-column justify-center mt-0">
        <div class="content-rcs">
          <div class="centerx" height="300px">
            <div class="media-content">
              <img
                class="img-preview-card"
                v-if="
                  this.rscCard.picture &&
                    this.rscCard.picture.fileUrl &&
                    this.showPreview
                "
                :src="this.rscCard.picture.fileUrl"
                alt
                srcset
              />
              <div class="mb-0">
                <Message
                  ref="mediaRef"
                  :emitType="true"
                  :onlyImages="true"
                  class="chat__input flex bg-white"
                  :has-errors="hasError"
                  @uploadedFile="processMedia"
                  :read-only="readOnly"
                />
              </div>
            </div>
            <!-- <vs-upload
              accept="image/*"
              :show-upload-button="false"
              :automatic="true"
              :text="uploadMessage"
              :limit="1"
              @on-delete="showFileInput(true)"
              @on-success="successUpload"
            />-->
            <div class="text-content">
              <div class="centerx labelx">
                <vs-input
                  class="input-rcs title"
                  :label="title + ' *'"
                  :placeholder="title"
                  v-model="rscCard.title"
                  :disabled="readOnly"
                />
                <vs-input
                  class="input-rcs subtitle"
                  :label="subtitle + ' *'"
                  :placeholder="subtitle"
                  v-model="rscCard.subTitle"
                  :disabled="readOnly"
                />
              </div>
              <div class="btn-box select-box pb-3">
                <div
                  v-for="index in 3"
                  :key="index"
                  class="selectItem d-flex input_button_card"
                >
                  <vs-input
                    :ref="`button-${index}`"
                    maxlength="20"
                    class="input-rcs subtitle"
                    v-model="rscCard.buttons[index].text"
                    :label="buttonLabel(index)"
                    :disabled="readOnly"
                  />
                  <LinkButton
                    @set_link="setLink"
                    :index="index"
                    :key="dateKey + index"
                    :link="links[index]"
                    :intent="intents[index]"
                    :read-only="readOnly"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { FILE_FORMATS_MAP } from '@/constants/constants'
import LinkButton from '../buttonsMessage/LinkButton.vue'
import Message from '../mediaMessage/Message'

export default {
  props: {
    keyItem: {
      Type: String,
      default: 0
    },
    indexItem: {
      Type: Number,
      default: 0
    },
    card: {
      Type: Object,
      default: null,
      required: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LinkButton,
    Message
  },
  data() {
    return {
      dateKey: new Date().getTime(),
      links: [],
      intents: [],
      showPreview: true,
      cardItem: null,
      componentKey: 0,
      mediaFile: null,
      hideAddOption: true,
      id: '',
      selectedSize: '',
      rscCard: {
        index: this.indexItem,
        title: '',
        subTitle: '',
        buttons: [{ text: '' }, { text: '' }, { text: '' }, { text: '' }],
        picture: null
      },
      items: [1, 2, 3],
      optionsSize: [],
      buttonDisabled: false,
      hasError: false,
      FILE_FORMATS_MAP
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', [
      'botMessageInEdit',
      'botMakerMessages',
      'botId',
      'activeChannelView'
    ]),
    uploadMessage() {
      return this.lang.botMaker.botEditor.messageType.RcsCard.addImage[
        this.languageSelected
      ]
    },
    title() {
      return this.lang.botMaker.botEditor.messageType.RcsCard.text_title[
        this.languageSelected
      ]
    },
    subtitle() {
      return this.lang.botMaker.botEditor.messageType.RcsCard.text_subtitle[
        this.languageSelected
      ]
    },
    savedFile() {
      if (this.mediaFile) {
        const saved = Object.assign(this.mediaFile)
        saved['mediaType'] = FILE_FORMATS_MAP[this.mediaFile.fileFormat]
        return saved
      } else if (this.rscCard.picture && this.rscCard.picture.fileUrl) {
        return this.rscCard.picture
      } else {
        return null
      }
    }
  },
  watch: {
    rscCard: {
      handler: function(n) {
        this.handleUpdate(n)
      },
      deep: true
    },
    savedFile(newValue) {
      this.rscCard.picture = newValue
    }
  },
  methods: {
    handleUpdate(n) {
      this.$emit('update', { card: this.transform(n), index: this.indexItem })
    },
    setLink([index, link, intent]) {
      this.links[index] = link
      this.intents[index] = intent
      this.dateKey = new Date().getTime()
      this.handleUpdate(this.rscCard)
    },
    ...mapActions('botMaker', ['SAVE_BOT_MESSAGE', 'GET_DIALOGS']),
    ...mapMutations('botMaker', ['SET_ACTIVE_CHANNEL_VIEW', 'UPDATE_MESSAGE']),
    checkBtns() {
      const el = this.rscCard.buttons.filter(textBtn => {
        return textBtn && textBtn != ''
      })
      if (el.length == this.buttonsInput) {
        this.hideAddOption = false
      } else {
        this.hideAddOption = true
      }
    },
    showPreviewF() {
      this.showPreview = true
      this.$refs.mediaRef.resetUpload()
    },
    hidePreviewF() {
      this.showPreview = false
    },
    processMedia(media) {
      if(media && media.fileData) {
        try {
          const file = media.fileData.get('file');
          if(file && file.type && file.type.includes("image/")) {
            this.mediaFile = media
            this.showPreview = false
          }
        } catch(e) {
          console.error(e);
          this.$notify.error(
            this.lang.botMaker.botEditor.messageType.RcsCard.mediaError.title[
              this.languageSelected
            ],
            this.lang.botMaker.botEditor.messageType.RcsCard.mediaError.text[
              this.languageSelected
            ]
          )
        }
      }
    },
    onCancel() {
      return true
    },
    successUpload() {
      alert('SUCCESS UPLOAD')
    },
    buttonLabel(index) {
      return `${
        this.lang.botMaker.botEditor.messageType.RcsCard.text_button[
          this.languageSelected
        ]
      } ${index}`
    },
    addOption() {
      this.buttonsInput++
    },
    showPreviewEnable() {
      this.showPreview = true
    },
    erase() {
      this.rscCard = {
        title: '',
        subTitle: '',
        buttons: [{ text: '' }, { text: '' }, { text: '' }, { text: '' }],
        picture: null
      }
      this.setupLinks()
    },
    setupLinks() {
      this.setupIntents()
      this.links = this.rscCard.buttons.map(function(el) {
        if (el.type && el.type === 'url') {
          return el.payload
        }
        return ''
      })
    },
    setupIntents() {
      this.intents = this.rscCard.buttons.map(function(el) {
        if (el.type && el.type === 'button' && el.text !== el.payload) {
          return el.payload
        }
        return ''
      })
    },
    clean() {
      if (this.cardItem) {
        this.showPreview = true
        this.rscCard = JSON.parse(JSON.stringify(this.cardItem))
      } else {
        this.rscCard = {
          title: '',
          subTitle: '',
          buttons: [{ text: '' }, { text: '' }, { text: '' }, { text: '' }],
          picture: null
        }
      }
      this.setupLinks()
    },
    transform(n) {
      const cardInfo = n
      const result = {
        header: {
          picture: n.picture
        },
        title: cardInfo.title,
        subtitle: cardInfo.subTitle,
        options: cardInfo.buttons.map((el, index) => {
          if (this.links[index] && this.links[index].trim() !== '') {
            return {
              type: 'url',
              text: el.text,
              payload: this.links[index]
            }
          } else {
            return {
              type: 'button',
              text: el.text,
              payload: this.intents[index] ? this.intents[index] : el.text
            }
          }
        })
      }
      return result
    },
    load(cardItem) {
      this.cardItem = {
        index: this.indexItem,
        title: cardItem.title,
        subTitle: cardItem.subtitle,
        buttons: cardItem.options,
        picture: {
          fileUrl: cardItem.header.url
        }
      }
      this.rscCard = { ...this.cardItem }
      this.setupLinks()
    }
  },
  mounted() {
    if (this.card) {
      delete this.card.index
      this.load(this.card)
    }
  }
}
</script>

<style lang="scss">
.rcs-card {
  .media-preview__preview.frame {
    box-shadow: none;
    img {
      margin: auto;
    }
  }
  .con-input-upload {
    border-radius: 0;
    height: 150px;
  }
  .select-box {
    background-color: white;
    //height: 200px;
    width: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .con-input-upload.disabled-upload {
    display: none !important;
  }
  .img-upload {
    width: 100%;
    margin: auto;
    height: 150px;
  }
}
.content-rcs {
  max-width: 360px;
  margin: auto;
  .media-content {
    .vs-con-input {
      width: 100%;
    }
  }
  .vs-con-input {
    width: 60%;
  }
  .vs-con-input-label.input-rcs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    &.title {
      .vs-input--label {
        font-size: 1.1rem;
        font-weight: bold;
      }
    }
    &.subtitle {
      padding-bottom: 15px;
    }
    .vs-input--label {
      width: 30%;
      height: fit-content;
      margin-top: auto;
      margin-bottom: auto;
      color: #737373;
    }
  }
  .text-content {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #f1f1f1;
  }
  background-color: #e2e3e4;
  border-radius: 20px;
  padding: 2px;
  padding-top: 0;
  overflow: hidden;
  width: 100%;
  .btn-upload-all {
    display: none;
  }
  .con-input-upload .text-input {
    color: #d0d2d3;
    &:before {
      content: '+';
      text-align: center;
      display: block;
      width: 100%;
      font-size: 20px;
    }
  }
  .con-img-upload {
    .img-upload {
      box-shadow: none;
      background: transparent;
    }
    box-shadow: none;
    background: transparent;
  }
  .con-input-upload {
    background-color: transparent;
    border: none;
  }
}

.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}

.btn-box.select-box {
  display: flex;
  flex-direction: column;
}
.img-preview-card {
  width: 100%;
  height: auto;
}
.input_button_card {
  align-items: center;
  .konecta_link_button {
    padding-bottom: 15px;
    padding-top: 5px;
    padding-right: 15px;
  }
}
</style>
